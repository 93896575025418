<template>
  <div class="gameRanking">
    <div class="topHeader" v-if="!$route.query.platform">
      <div class="left" @click="$router.back()"></div>
      <div class="order_text">{{ $route.query.title || '拉新排行榜' }}</div>
      <div class="right"></div>
    </div>
    <div class="banner">
      <img src="../../assets/images/xsxfText.png" />
      <div v-if="isInit">
        结束倒计时：
        <span>{{ time | limitTimeFilterd }}</span> 天
        <span>{{ time | limitTimeFilterh }}</span> 时
        <span>{{ time | limitTimeFilterm }}</span> 分
        <span>{{ time | limitTimeFilters }}</span> 秒
      </div>
    </div>
    <div class="list">
      <div class="tab">
        <div class="tabItem" v-for="item in tabList" :key="item">
          {{ item }}
        </div>
      </div>
      <div
        class="listItem"
        :class="
          key === 0 ? 'one' : key === 1 ? 'two' : key === 2 ? 'there' : null
        "
        v-for="(item, key) in list"
        :key="key"
      >
        <div>{{ key + 1 }}</div>
        <div>{{ item.nickname || '--' }}</div>
        <div>{{ item.pay_price || '--' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formateTimeStamp } from '../../utils/tools';
export default {
  name: 'gameRanking',
  data() {
    return {
      tabList: ['排名', '昵称', '消费金额'],
      list: [],
      total: 0,
      time: 0,
      ticker: null,
      isInit: false,
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    limitTimeFilterd(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        return formateLimitTimes.day;
      } else {
        const twtxt = `0`;
        return twtxt;
      }
    },
    limitTimeFilterh(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        return formateLimitTimes.hour;
      } else {
        const twtxt = `0`;
        return twtxt;
      }
    },
    limitTimeFilterm(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        return formateLimitTimes.min;
      } else {
        const twtxt = `0`;
        return twtxt;
      }
    },
    limitTimeFilters(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        return formateLimitTimes.seconds;
      } else {
        const twtxt = `0`;
        return twtxt;
      }
    },
  },
  beforeDestroy() {
    this.clearTime();
  },
  methods: {
    clearTime() {
      if (this.ticker) {
        clearInterval(this.ticker);
      }
    },
    beginTimer() {
      this.ticker = setInterval(() => {
        //数据循环，因为要每一列数据都要倒计时，所以要对每一列的数据进行处理
        if (this.time > 0) {
          this.time = this.time - 1;
        } else {
          this.clearTime();
        }
      }, 1000);
    },
    getList() {
      this.$api.buyRankingList().then((e) => {
        if (e.code === 0) {
          this.list = e.data;
          this.total = e.total;
          this.isInit = true;
          this.time = e.time;
          if (this.time) {
            this.beginTimer();
          }
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gameRanking {
  background: url(../../assets/images/xsxfBg.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  padding-bottom: 20px;
  .header {
    height: 50px;
    padding: 0 20px;
    position: relative;
    .left {
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      top: 50%;
      position: absolute;
      transform: rotate(-45deg) translateY(-50%);
    }
  }
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px;
    text-align: center;
    flex-direction: column;
    img {
      max-width: 70%;
      margin: 0 auto;
    }
    div {
      color: #f9f9f7;
      margin-top: 15px;
      font-size: 15px;
      span {
        color: #ff911b;
      }
    }
  }
  .list {
    background: #f5f4f3;
    border-radius: 10px;
    margin: 0 17px 15px;
    padding: 10px 0 0;
    min-height: 100px;
    overflow: hidden;
    .info {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      // line-height: 35px;
    }
    &.minHeight {
      min-height: 200px;
    }
    .tab {
      display: flex;
      .tabItem {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #815c04;
        flex: 1;
        text-align: center;
        padding: 10px 0;
        // &:nth-child(3) {
        //   flex: 2;
        // }
        // &:nth-child(4),
        // &:nth-child(5) {
        //   flex: 1.5;
        // }
      }
    }
    .listItem {
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:nth-child(even) {
        background: #ede5d5;
      }
      div {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #977363;
        flex: 1;
        text-align: center;
        min-width: 0px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding: 0 3px;
        // &:nth-child(3) {
        //   flex: 2;
        // }
        // &:nth-child(4),
        // &:nth-child(5) {
        //   flex: 1.5;
        // }
      }
      &.one {
        div {
          color: #d14546;
        }
      }
      &.two {
        div {
          color: #fc9120;
        }
      }
      &.there {
        div {
          color: #e1b200;
        }
      }
    }
  }
  .info {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    padding-left: 30px;
  }
}
.topHeader {
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    width: 10px;
    height: 10px;
    left: 20px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    top: 50%;
    position: absolute;
    transform: rotate(-45deg) translateY(-50%);
  }
  .right {
    position: absolute;
    transform: translateY(-50%);
    right: 16px;
    top: 50%;
    font-size: 12px;
  }
  .order_text {
    width: 50%;
    font-size: 18px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
  }
}
</style>
